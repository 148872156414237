<template>
	<div class="grid">
		<div class="col-12">
			<div class="card">
				<Toast/>
				<DataTable ref="dt" :value="guides" v-model:selection="selectedRemisionGuide" selectionMode="single" @rowSelect="onRemisionGuideSelected" dataKey="id" :paginator="true" :rows="20" :filters="filters"
							paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[20,35,50]"
							currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} guías" responsiveLayout="scroll">
					<template #header>
						<div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
							<h5 class="m-0">Guías de Remisión</h5>
							<div id="table-header-inputs-container">
								<span id="table-search-input" class="block mt-2 md:mt-0 p-input-icon-left">
									<i class="pi pi-search" />
									<InputText v-model="filters['global'].value" placeholder="Search..." />
								</span>
								<span id="table-buttons-container" class="block mt-2 md:mt-0">
									<Button icon="pi pi-plus" class="p-button-rounded p-button-success mr-2" @click="openNew" />
									<!-- <Button icon="pi pi-download" class="p-button-rounded p-button-help mr-2" @click="toggleDownloadMenu($event)" /> -->
									<OverlayPanel ref="op1" appendTo="body" :showCloseIcon="false" id="overlay_panel" style="width: 250px">
										<h5>Rango de fechas</h5>
										<div class="grid" style="display: block;">
											<div class="col-12 md:col-12">
												<div class="field-radiobutton mb-0" style="display: block;">
													<label>Inicio</label>
													<Calendar :showIcon="true" v-model="initialFilterDate" dateFormat="dd/mm/yy"></Calendar>
												</div>
											</div>
											<div class="col-12 md:col-12">
												<div class="field-radiobutton mb-0" style="display: block;">
													<label>Fin</label>
													<Calendar :showIcon="true" v-model="finalFilterDate" dateFormat="dd/mm/yy"></Calendar>
												</div>
											</div>
										</div>
										<Button class="p-button-success mr-2" style="width: 100%;  margin-top: 15px;" :loading="this.loadingDownloadButton" label="Descargar" @click="downloadRemisionGuide" />
									</OverlayPanel>
								</span>
							</div>
							
						</div>
					</template>
                    <Column field="sequence" header="Serial" :sortable="true" headerStyle="width:20%; min-width:8rem;">
						<template #body="slotProps">
							{{ (slotProps.data.edoc !== null) ? slotProps.data.edoc.sequence : slotProps.data.id.toString().padStart(9, "0") }}
						</template>
					</Column>
                    <Column field="saleSerialNumber" header="Factura" :sortable="true" headerStyle="width:20%; min-width:10rem;">
						<template #body="slotProps">
							{{ slotProps.data.saleSerialNumber || "-" }}
						</template>
					</Column>
                    <Column field="saleCustomer" header="Cliente" :sortable="true" headerStyle="width:25%; min-width:10rem;">
						<template #body="slotProps">
							{{ slotProps.data.saleCustomer || "-" }}
						</template>
					</Column>
					<Column field="createdAtFormatted" header="Fecha" :sortable="true" headerStyle="width:20%; min-width:10rem;"></Column>
					<Column field="edocStatus" header="Estado Documento" :sortable="true" headerStyle="width:25%; min-width:10rem;">
						<template #body="slotProps">
							<span :class="'status-badge status-' + slotProps.data.edocStateBadgeColor">{{slotProps.data.edocState}}</span>
						</template>
					</Column>
				</DataTable>
                <Dialog v-model:visible="showSalePicker" :style="{width: '450px'}" header="Seleccione una factura" :modal="true">
					<AutoComplete placeholder="Buscar por cliente..." id="dd" :dropdown="false" :multiple="false" v-model="selectedSale" @complete="searchSales($event)" inputStyle="width: 100%;" style="width: 100%;" :selectOnFocus="true"/>
                    <DataTable :value="this.sales" selectionMode="single" @rowSelect="onSaleSelected" :scrollable="true" scrollHeight="400px" scrollDirection="both" class="mt-3 editable-cells-table">
                        <Column field="serialNumber" header="Serie" :style="{width: '30%','min-width':'50px'}"></Column>
                        <Column field="customer.name" header="Cliente" :style="{width: '50%','min-width':'100px'}"></Column>
                        <Column field="total" header="Total" :style="{width: '20%','min-width':'100px'}">
                            <template #body="slotProps">
                                ${{ slotProps.data.total.toFixed(2) }}
                            </template>
                        </Column>
                    </DataTable>
					<template #footer>
						<Button label="Continuar sin factura" class="p-button-secondary mr-2 mb-2" @click="continueWithoutReceipt"/>
					</template>
                </Dialog>
			</div>
		</div>
	</div>

</template>

<script>
import download from 'downloadjs';
import {FilterMatchMode} from 'primevue/api';
import httpRequest from '../../service/httpRequest';
import RemisionGuide from '../../models/remisionGuide';
import Sale from '../../models/sale';

export default {
	data() {
		return {
			guides: [],
            guide: new RemisionGuide(),
            selectedRemisionGuide: null,
            sales: [],
            selectedSale: null,
			filters: {},
            showSalePicker: false,
			initialFilterDate: null,
			finalFilterDate: null,
			loadingDownloadButton: false,
		}
	},
	created() {
        this.initFilters();
        this.getGuides();
	},
	methods: {
		openNew() {
            this.showSalePicker = true
		},
		toggleDownloadMenu(event) {
			this.$refs.op1.toggle(event);
		},
		confirmDeleteSelected() {
			this.deleteSalesDialog = true;
		},
		initFilters() {
            this.filters = {
                'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
            }
        },
		onRemisionGuideSelected(event) {
			let sale = event.data;
			this.$router.push('form?id=' + sale.id);
		},
        onSaleSelected(event) {
            let sale = new Sale(event.data);
            this.$router.push('form?saleId='+sale.id);
        },
        async searchSales(event) {
            let filter = event.query;
            let response = await httpRequest.getSales(filter, null, null, null, null, null, true);
            if (response.status == 200) {
                let sales = response.data;
                this.sales = sales.map(function(data) { return new Sale(data)});
            } else { this.sales = [] }
        },
		async getGuides() {
            let response = await httpRequest.getGuides();
            if (response.status == 200) {
                let guides = response.data;
                this.guides = guides.map(function(data) { return new RemisionGuide(data)});
            } 
			// else { console.log("Error: ", response.status); }
        },
		async downloadRemisionGuide() {
			// Make date verification
			if (this.initialFilterDate !== null && this.finalFilterDate !== null && this.initialFilterDate>this.finalFilterDate) {
				this.$toast.add({severity:'error', summary: 'Fechas incorrectas', detail: 'La fecha inicial no puede ser mayor a la fecha final', life: 3000});
				return;
			}

			let initialDate = this.initialFilterDate !== null ? this.initialFilterDate.toISOString() : null
			let endDate = this.finalFilterDate !== null ? this.finalFilterDate.toISOString() : null
			this.loadingDownloadButton = true;
			const data = await httpRequest.downloadRemisionGuide(initialDate, endDate);
			download(data, "ventas" + ".xlsx", "application/vnd.ms-excel");
			this.loadingDownloadButton = false;
		},
		continueWithoutReceipt() {
			this.$router.push('form');
		},
	}
}
</script>

<style scoped lang="scss">
@import '../../assets/demo/badges.scss';

@media screen and (max-width: 769px) {
	#table-header-inputs-container {
		display: block;
	}
	#table-search-input {
		margin: 15px 0px !important;
	}
}
@media screen and (min-width: 769px) {
	#table-header-inputs-container {
		display: flex;
	}
	#table-search-input {
		margin: 0px 15px;
	}
}

.status-badge {
    border-radius: var(--border-radius);
    padding: .25em .5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: .3px;
}

.status-badge {
    &.status-green {
        background: #C8E6C9;
        color: #256029;
    }

    &.status-red {
        background: #FFCDD2;
        color: #C63737;
    }

    &.status-yellow {
        background: #FEEDAF;
        color: #8A5340;
    }

    &.status-orange {
        background: #ffa177;
        color: #da2727;
    }

	&.status-blue {
        background: #B3E5FC;
        color: #23677B;
    }

	&.status-gray {
        background: #d6d8d9;
        color: #464747;
    }
}
</style>
